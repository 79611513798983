

import React from 'react'
import SeoPage from '../components/SeoPage'

import { StaticImage } from 'gatsby-plugin-image'
import LeadImage from '../images/avantages-consultation-meilleurs-avocats-legal-mirabel-768x512.jpeg'



const Mirabel = ({ data, location, ...props }) => (
    <SeoPage location={location}
        title="Les avantages de consulter les meilleurs avocats dans la région de Mirabel - Soumissions Avocat"
        description="Ne tardez pas et trouvez votre avocat à Mirabel dès maintenant pour votre affaire personnelle ou d'entreprise ! Il sera plaideur, négociateur, consultant ou votre représentant tout dépendemment de votre litige. Réglez votre dossier judicieusement avec l'assistance d'un avocat compétent de Mirabel."
        image={LeadImage}>
        <h1>Les avantages de consulter les meilleurs avocats dans la région de Mirabel</h1>
        <p>
            Engager un avocat, c’est d’admettre la gravité de sa situation juridique,
            mais c’est également s’outiller pour régler son problème avant qu’il ne
            dégénère. Une simple dispute entre voisins peut rapidement dégénérer en
            bataille légale sans fin si les arguments passent d’une dispute de droit à
            vulgaire conflit personnel. Pour éviter un tel saut insensé, il vous faut
            retenir les services d’un professionnel de la résolution de disputes et de
            différends, soit l’avocat!
        </p>
        <p>
            <StaticImage placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}
                src="../images/avantages-consultation-meilleurs-avocats-legal-mirabel-768x512.jpeg"
                alt="avantages-consultation-meilleurs-avocats-legal-mirabel"

            />
        </p>
        <p>
            En effet, les juristes sont des professionnels très qualifiés en matière de
            résolution de disputes et de litiges. Que vous ayez simplement besoin de
            quelques conseils ou d’un service de représentation juridique intégral,
            vous ne pouvez pas vous tromper en engageant un avocat. Voyez donc ce qu’un
            tel expert a à vous offrir dans la région de Mirabel!
        </p>
        <h2>
            Quel rôle occupe l’avocat dans le règlement de litiges à Mirabel
        </h2>
        <p>
            Les avocats sont des professionnels portant plusieurs chapeaux et acceptant
            le fardeau de diverses responsabilités. Les expertises légales sont très
            variées et le rôle de l’avocat s’adapte également aux besoins de sa
            clientèle et des dossiers qui lui sont soumis. Tous les clients ne
            cherchent pas à se rendre en Cour suprême, alors il faut savoir faire
            preuve de versatilité dans ce domaine. C’est en occupant ces divers rôles
            que les avocats de Mirabel savent se rendre utiles!
        </p>
        <p>
            <strong>Consultant :</strong>
            Plusieurs dossiers demandent une attention légale sans toutefois exiger une
            représentation complète. Bien des gens sont simplement à la recherche de
            directions légales générales sur la marche à suivre pour éviter que leur
            dossier ne s’éternise. Une telle clientèle n’est certes pas prête à payer
            pour des centaines d’heures d’honoraires légaux! Sachant cela, les avocats
            sont prêts à offrir de simples séances de consultation ou encore émettre
            des avis juridiques par écrit.
        </p>
        <p>
            <strong>Représentant</strong>
            : Lorsqu’un avocat accepte le mandat de régler votre dossier, il devient
            votre représentant légal chargé de faire valoir vos droits et vos intérêts
            vis-à-vis l’autre partie. Il devient donc votre mandataire, en quelque
            sorte. Que votre cause se rende en cour ou non, vous paierez pour qu’un
            avocat devienne votre porte-parole autant devant la cour qu’au courant du
            règlement de votre dossier.
        </p>
        <p>
            <strong>Négociateur</strong>
            : Comme aller en cour n’est pas toujours un avantage, bien des avocats
            tentent par tous les moyens de régler le dossier hors cour. Cela implique
            inévitablement des négociations exhaustives, complexes et parfois
            envenimées. Il faut réellement avoir des talents de négociateur ainsi
            qu’une expertise légale développée pour convaincre quelqu’un de changer son
            fusil d’épaule.
        </p>
        <p>
            <strong>
                <br />
                Plaideur :
            </strong>
            Ce qui distingue l’avocat des autres professionnels est l’aspect parfois
            théâtral de son rôle. En effet, un avocat qui plaide devant le tribunal
            doit non seulement respecter le décorum très formel exigé, mais également
            débattre de façon à convaincre le juge que le droit le favorise aux dépens
            de l’adversaire. Il faut plus qu’un talent d’orateur pour parvenir à
            accomplir pareil exploit; il vous faut un avocat plaideur!
        </p>
        <p>
            <strong>
                Nous l’avons mentionné, l’avocat est un professionnel versatile. Il
                sait s’adapter aux besoins de sa clientèle pour se rendre utile en
                toute circonstance, et ce, quelle que soit la cause lui étant soumise.
            </strong>
        </p>
        <h2>
            Pourquoi envisager la médiation familiale?
        </h2>
        <p>
            Qu’est-ce qui distingue le droit familial des autres domaines de pratique
            de l’avocat? Le degré d’émotions impliqué dans chacune des disputes.
            Lorsque les parties sont à fleur de peau et que la mauvaise foi commence à
            se faire ressentir, on perd de vue la véritable nature du conflit, ce qui
            rend la résolution paisible on ne peut plus difficile.
        </p>
        <p>
            Conscient de cette problématique, le gouvernement met à la disposition des
            citoyens des services de médiation familiale visant à faciliter la
            résolution des différends et ainsi prévenir les litiges. Pour quels recours
            pouvez-vous utiliser ce type de médiation?
        </p>
        <p>
            <StaticImage placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}
                src="../images/services-avocats-mediation-adoption-divorce-seperation-mirabel.jpeg"
                alt="services-avocats-mediation-adoption-divorce-seperation-mirabel"

            />
        </p>
        <p>
            <strong>Régler une séparation ou un divorce</strong>
            . Surtout pertinents dans le cadre d’un divorce impliquant des enfants, les
            services de médiation familiale sont mis à la disposition    <strong>gratuite</strong> des couples ayant un enfant mineur ou majeur à
            leur charge. Ce service inclut une séance d’information d’au moins 2.5
            heures sur la parentalité après le divorce ainsi que 5 heures de médiation
            visant à régler les modalités de la séparation. L’objectif est évidemment
            de clore le dossier de façon aussi harmonieuse que possible tout en évitant
            que les conflits personnels ne viennent nuire aux procédures.
        </p>
        <p>
            <strong>Dossiers d’adoption.</strong>
            La médiation familiale dans un contexte d’adoption vise principalement à
            établir un accord de communication pour que l’enfant adopté puisse
            maintenir des liens avec sa famille biologique. Les parents adoptifs et
            biologiques bénéficieront donc des services de médiation afin de faciliter
            la mise en place d’une telle entente. Il s’agit d’une initiative plutôt
            délicate, et c’est pourquoi des services publics de médiation d’une durée
            de 5 heures sont mis en place gratuitement.
        </p>
        <p>
            <strong> </strong>
        </p>
        <p>
            <strong>
                Quels sont les avantages de procéder à une séance de médiation
                familiale?
            </strong>
            L’avantage principal de la médiation est son efficacité à prévenir les
            conflits. En prenant un dossier très personnel et haut en émotions pour le
            mettre dans un cadre formel, les chances de parvenir à une entente
            équitable augmentent. Qui plus est, l’intérêt de l’enfant est toujours mis
            à l’avant-plan de tout accord lors du processus de médiation. C’est là la
            clé d’une entente viable en droit de la famille!
        </p>
        <h2>
            Pour quels motifs pouvez-vous résilier votre bail?
        </h2>
        <p>
            L’accès à la propriété n’est pas le rêve de tout le monde et dans le
            contexte actuel, ce n’est pas nécessairement une mauvaise chose! Cela
            implique toutefois qu’énormément de gens sont liés par des baux
            résidentiels les liant au locateur de l’immeuble dans lequel ils résident.
        </p>
        <p>
            Bien qu’une grande partie des locataires de la province n’éprouve aucun
            problème avec leur propriétaire et vice versa, d’autres sont aux prises
            avec des situations locatives moins harmonieuses. Pour de tels individus,
            il importe de rappeler les <strong>motifs de résiliation de bail</strong>
            en droit québécois!
        </p>
        <p>
            <strong>Résiliation par le locateur : </strong>
            Lorsque c’est le locataire qui pose problème et non le locataire, il est
            possible de mettre un terme au contrat de location pour des motifs sérieux.
            Comme la loi cherche à protéger les droits du locataire à son maintien dans
            les lieux et à la jouissance paisible de son logement, voici les motifs qui
            peuvent justifier la résiliation :
        </p>
        <ul>
            <li>
                <em>
                    Le locataire trouble la jouissance paisible des autres occupants de
                    l’immeuble.
                </em>
            </li>
            <li>
                <em>Le locataire cause une détérioration anormale du logement.</em>
            </li>
            <li>
                <em>Le paiement du loyer est en retard de plus de 3 semaines.</em>
            </li>
            <li>
                <em>Le paiement du loyer est en retard fréquemment.</em>
            </li>
        </ul>
        <p>
            <strong>
                <br />
            </strong>
            Contrairement au locataire, le locateur doit toujours passer par la Régie
            du logement pour mettre un terme au bail de location. Vous verrez ci-bas
            qu’en certaines circonstances, le locataire pourra résilier le bain sans
            recours!
        </p>
        <p>
            <strong>
                Résiliation par le locataire sans recours à la Régie du logement :
            </strong>
            La règle générale veut qu’autant le locateur que le locataire doivent
            procéder à un recours à la régie du logement pour résilier un bail de
            location. Toutefois, dans les situations suivantes, le bail pourra être
            résilié sans une telle formalité;
        </p>
        <ul>
            <li>
                <em>Le locataire obtient une habitation à loyer modique.</em>
            </li>
            <li>
                <em>
                    Le locataire n’a plus la capacité d’entretenir le logement en
                    raison d’un handicapé
                </em>
            </li>
            <li>
                <em>Le locataire est admis dans un CHSLD de façon permanente.</em>
            </li>
            <li>
                <em>
                    Un cas de violence conjugale ou de violence sexuelle menace la
                    sécurité d’un conjoint ou d’un enfant abitant le logement.
                </em>
            </li>
            <li>
                <em>
                    L’étudiant louant le logement cesse de fréquenter son établissement
                    scolaire à temps plein.
                </em>
            </li>
            <li>
                <em>Décès du locataire.</em>
            </li>
        </ul>
        <p>
            <strong>
                Résiliation par le locataire avec recours à la Régie du logement :
            </strong>
            Le recours à la régie du logement vise à prouver la faute ou les torts
            allégués au locateur. Les situations suivantes demandent donc qu’un recours
            soit entrepris :
        </p>
        <p>
            <StaticImage placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}
                src="../images/resiliation-bail-locateur-locataire-recours-regie-logement-mirabel.jpeg"
                alt="resiliation-bail-locateur-locataire-recours-regie-logement-mirabel"

            />
        </p>
        <ul>
            <li>
                <em>Le locateur empêche la jouissance paisible des lieux.</em>
            </li>
            <li>
                <em>
                    Le locateur ne maintient pas les lieux dans un état habitable.,
                </em>
            </li>
            <li>
                <em>Le locateur n’effectue pas les réparations nécessaires.</em>
            </li>
            <li>
                <em>
                    Le locateur laisse les lieux dans un état insalubre et inhabitable.
                </em>
            </li>
            <li>
                <em>
                    Le locateur transforme la destination du bâtiment (ex : Conversion
                    d’un immeuble locatif en copropriété).
                </em>
            </li>
        </ul>
        <p>
            <strong>
                <br />
                Les motifs interdits pour résilier un bail :
            </strong>
            La loi a ses limites! Cela signifie que certains motifs de résiliation de
            bail ne tiendront simplement pas la route devant la Régie du logement et
            seront rejetés d’office!
        </p>
        <ul>
            <li>
                <em>Troubles financiers</em>
            </li>
            <li>
                <em>Relocalisation due à l’emploi</em>
            </li>
            <li>
                <em>Problèmes financiers/insolvabilité</em>
            </li>
            <li>
                <em>Divorce/séparation</em>
            </li>
            <li>
                <em>Achat d’une propriété</em>
            </li>
        </ul>
        <p>
            <em>
                <br />
            </em>
            <strong>
                Les recours en droit immobilier et en matière de louage sont toujours
                complexes, car la loi met en place des protections autant pour les
                locataires que pour les propriétaires. Dans ce type de dispute, il
                n’est pas toujours évident de discerner qui a raison! C’est pourquoi il
                est impératif d’engager un avocat spécialisé dans ce domaine
                particulier!
            </strong>
        </p>
        <p>
            <strong> </strong>
        </p>
        <h2>
            Qu’est-ce que le partage du patrimoine familial? Devez-vous engager un
            avocat?
        </h2>
        <p>
            Le mariage est réellement pour le meilleur et pour le pire au point où cela
            inclut les biens acquis lors du mariage! La mise en commun des biens est ce
            que la loi qualifie du patrimoine familial. Seulement quatre catégories de
            biens entreront dans ce patrimoine, soit les<strong>résidences familiales</strong>, les<strong>régimes de retraite</strong>, les<strong>véhicules de la famille</strong> ainsi que les    <strong>meubles du ménage</strong>.
        </p>
        <p>
            Quel est l’intérêt de connaître la composition d’une telle entité? Tous ces
            biens seront partagés également lors du divorce! Si vous êtes dans une
            telle situation, vous auriez intérêt à en apprendre davantage sur le sujet!
        </p>
        <p>
            <strong>
                À quel moment s’effectue le partage du patrimoine familial?
            </strong>
            On partage le patrimoine lors de la dissolution de l’union qui ne peut
            survenir qu’en deux occasions, soit au <strong>divorce</strong> ou    <strong>encore</strong> au décès de l’un des conjoints. À l’un de ces deux
            moments, un avocat procèdera au calcul du patrimoine familial pour évaluer
            le montant divisé et attribuable à chacun des époux. Notez cependant que le
            patrimoine confère un droit à la moitié de la valeur totale et non un droit
            de propriété sur le bien.
        </p>
        <p>
            <strong>
                <br />
                Quelle est la différence avec le régime matrimonial?
            </strong>
            Les régimes matrimoniaux visent à encadrer la gestion et la répartition des
            biens n’entrant pas dans la définition du patrimoine familial. Les époux
            devront choisir entre la société d’acquêts, la communauté de biens et la
            séparation de biens.
        </p>
        <p>
            <strong>
                <br />
                Comment un avocat en droit de la famille peut-il aider?
            </strong>
            Les avocats peuvent vous assister autant au niveau du choix que de la
            séparation des régimes matrimoniaux. Comme le droit matrimonial est une
            spécialité au sein du droit, il vaut mieux engager un avocat qui s’y
            connaît dans le domaine!
        </p>
        <h2>
            La procuration rédigée par un avocat : un outil plus efficace!
        </h2>
        <p>
            La procuration est un outil juridique fort utile au Québec. Elle permet
            d’administrer les biens et l’argent d’une autre personne et d’effectuer des
            actions administratives en son nom lorsque celle-ci en est incapable. Si la
            procuration est pratique, elle est également risquée lorsque le mandat
            conféré est vague ou que les attentes du mandataire et du mandant
            divergent. De plus, avec l’émergence du mandat donnée en prévision de
            l’inaptitude, une certaine confusion se fait ressentir entre ce document et
            la procuration. Nous tenterons de vous éclairer le chemin!
        </p>
        <p>
            <strong>
                Une procuration doit-elle être écrite pour être valide au Québec?
            </strong>
            Rien dans la loi n’exige qu’une procuration soit constatée par écrit.
            Toutefois, cela sera nécessaire pour exercer les pouvoirs prévus dans le
            mandat auprès de différentes institutions. Par exemple, les banques et
            autres institutions exigent une procuration écrite pour retirer des sommes
            d’argent appartenant au mandant (celui qui écrit le mandat).
            <strong>
                <br />
            </strong>
        </p>
        <p>
            <StaticImage placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}
                src="../images/procuration-mandat-inaptitude-pouvoirs-avocat-mirabel.jpeg"
                alt="procuration-mandat-inaptitude-pouvoirs-avocat-mirabel"

            />
        </p>
        <p>
            <strong>
                <br />
                Quel type d’administration est couverte par la procuration?
            </strong>
            Un tel document ne couvre que l’administration des biens d’une personne.
            Ainsi, le mandant peut préparer une procuration permettant au mandataire de
            conclure des transactions, de retirer de l’argent, de vendre se maison,
            etc.
        </p>
        <p>
            <strong>
                Quelle est la différence entre la procuration spéciale ou générale?
            </strong>
            Des différences considérables existent entre la procuration générale et
            celle dite spéciale en termes d’étendue de pouvoirs conférés au mandataire.
            Dans le cas d’une procuration spéciale, vous octroyez au mandataire le
            droit de réaliser, en votre nom, une action spécifique et détaillée dans la
            procuration. Cette action peut être celle d’aliéner un bien, de le vendre
            ou de le réparer. Votre mandataire ne détient donc que les pouvoirs
            explicitement détaillés.
        </p>
        <p>
            En revanche, la <strong>procuration générale</strong> est celle qui est
            écrite dans des termes généraux, confiant ainsi à votre mandataire la
            simple administration tous vos biens et de vos affaires. On comprend donc
            qu’il peut être dangereux de préparer une procuration trop générale,
            surtout si un avocat ne vous a pas conseillé au préalable.
        </p>
        <p>
            Toutefois, la simple administration que confère une procuration générale ne
            permet pas au mandataire de se départir de vos biens ou de les aliéner.
            Celui-ci ne pourra que les conserver et exercer les pouvoirs qui découlent
            naturellement du devoir qui lui est confié.
        </p>
        <p>
            <strong>Comment s’accepte le mandat conféré par la procuration? </strong>
            Le mandataire peut d’abord accepter expressément le mandat qui lui est
            confié, que ce soit verbalement ou par écrit. Toutefois, l’acceptation
            tacite du mandat est également possible lorsque le mandataire commence à
            effectuer les devoirs prévus dans la procuration sans avoir accepté
            expressément. Il sera tenu aux mêmes obligations que s’il avait accepté de
            manière expresse.
        </p>
        <p>
            <strong>
                Dans quelles circonstances pouvez-vous rédiger une procuration?
            </strong>
            Vous devez être aptes pour faire une procuration! Ce document peut s’avérer
            utile lorsque vous vous absentez pour un voyage, lorsque vous avez une
            capacité physique qui limite votre capacité à accomplir certaines actions,
            du moment que votre capacité intellectuelle n’est pas affectée.
        </p>
        <p>
            La différence entre une procuration et un mandat d’inaptitude! Alors que la
            procuration ne vise que l’administration des biens et des actifs d’une
            personne, le mandat d’inaptitude, quant à lui, vise autant à gérer les
            biens d’une personne qu’à prendre les décisions quant aux soins reçus, aux
            conditions d’hébergement et aux traitements de fin de vie. Comme ce
            document octroi des pouvoirs beaucoup plus larges et importants que la
            procuration, il n’est pas étonnant que la loi prévoit des formalités plus
            exigeantes pour le former.
        </p>
        <p>
            <strong>
                Dans les deux cas, un mandat ne permet au mandataire que d’agir dans
                les limites des pouvoirs octroyés par le mandat, et ce, jusqu’à
                l’expiration de ce dernier. Lorsqu’un mandataire outrepasse les limites
                du mandat, il s’expose à des poursuites et engage sa propre
                responsabilité civile.
            </strong>
        </p>
        <h2>
            Les pouvoirs et obligations du mandant et du mandataire détenant une
            procuration
        </h2>
        <p>
            Bien que ce soit principalement les principes de la procuration générale ou
            spéciale qui dictent la conduite du mandataire, les contrats de mandat
            comprennent également des obligations implicites qui découlent de la nature
            du contrat. Il s’agit cependant d’une route à deux sens, le mandataire
            ayant des obligations à l’égard du mandant et vice versa. Des recours
            peuvent d’ailleurs être intentés lorsque ces obligations sont violées.
            Voici donc les principaux devoirs auxquels ceux-ci sont tenus.
        </p>
        <p>
            <strong>Concernant le mandataire :</strong>
            Étant celui qui reçoit la tâche de représenter le mandant dans
            l’accomplissement d’un devoir précis ou général, le mandataire se voit
            imposer des devoirs de reddition de comptes envers le mandant, dont :
        </p>
        <p>
            <em>L’accomplissement personnel : </em>
            Le contrat de mandat en est un que la loi considère comme étant intuitu
            personae, c’est-à-dire, confié à la personne en considérant que c’est elle
            personnellement qui accomplirait la tâche. Ainsi, le mandant est en droit
            de s’attendre à ce que ce soit le mandataire lui-même qui accomplisse le
            devoir confié, signifiant que celui-ci ne peut pas déléguer le mandat à un
            tiers, sous réserve d’une disposition contraire dans le contrat.
            <br />
            <em>
                <br />
                L’assistance :
            </em>
            Bien qu’il ne puisse pas déléguer son mandat à un tiers, le mandant peut
            tout de même obtenir l’assistance qu’il juge nécessaire pour accomplir son
            devoir. Cela peut prendre plusieurs formes, dont celle de conseils
            d’experts.
        </p>
        <p>
            Prudence et diligence : On illustre souvent le devoir de prudence et de
            diligence comme le comportement qu’une personne raisonnable adopterait dans
            les mêmes circonstances que le mandataire. Lorsque le mandataire faillit à
            ces obligations, il s’expose aux représailles du mandant.
        </p>
        <p>
            Information : Le mandataire se doit également d’informer le mandant de
            l’accomplissement et de l’évolution de l’accomplissement du mandat. Cela
            permet d’assurer la conformité des actes posés.
            <em>
                <br />
            </em>
            <strong>
                <br />
            </strong>
            <br />
            <strong>Concernant le mandant : </strong>
            Étant celui qui confie la tâche au mandataire, il possède des devoirs
            envers ce dernier, mais également envers les tiers avec lesquels le
            mandataire contracte pour le compte du mandant. Il est donc tenu aux
            obligations suivantes :
            <br />
            <strong>
                <br />
            </strong>
            <em>Collaboration :</em>
            Ce devoir se définit difficilement sur le plan théorique, mais sur le plan
            pratique, le mandant se doit simplement d’aider le mandataire à accomplir
            sa tâche, que ce soit en lui donnant les informations ou avances
            nécessaires.
        </p>
        <p>
            <em>Rémunération </em>
            : Ce ne sont pas tous les mandats qui sont donnés en échange de
            rémunération; plusieurs sont effectués à titre gratuit. Cependant, lorsque
            ce n’est pas le cas, le mandataire est tenu de payer la rémunération
            convenue.
        </p>
        <p>
            <em>Remboursement des dépenses</em>
            : L’accomplissement de certaines démarches implique des frais, que ceux-ci
            soient administratifs, légaux ou autres. Encore une fois, c’est le devoir
            du mandant de veiller à rembourser les dépenses effectuées dans le cadre de
            l’exécution du mandat, tant qu’elles ont bien été effectuées pour la
            réalisation de celui-ci.
        </p>
        <p>
            <strong>
                Vous croyez que votre mandataire ou votre mandant n’a pas respecté ses
                obligations envers vous? Un avocat en droit contractuel se fera plaisir
                d’analyser la situation avec vous! Il suffit de contacter Soumissions
                Avocat pour rencontrer un avocat à Mirabel.
            </strong>
        </p>
        <h2>
            Qui est responsable des actes du mandataire qui excède les pouvoirs de son
            mandat?
        </h2>
        <p>
            Le principe général de droit civil veut que le mandant soit responsable des
            actes posés par son mandataire, tant que celui-ci demeure dans les limites
            de l’accomplissement de son mandat. Bien que ce principe soit simple, il
            n’est pas toujours évident de déterminer si les actes du mandataire sont
            réellement dans les limites de ce que la procuration stipulait. Voici les
            situations que vous êtes susceptibles de rencontrer!
        </p>
        <p>
            <StaticImage placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}
                src="../images/procuration-mandat-mandataire-avocat-768x512.jpg"
                alt="procuration mandat mandataire avocat"

            />
        </p>
        <p>
            <strong>Le cas de l’ancien mandataire : </strong>
            Si une personne n’a jamais été votre mandataire, il va de soi que vous ne
            serez pas tenu responsable des actes de cette personne. Cela étant dit, il
            faut nuancer ces propos si la personne en question est votre ancien
            mandataire. Dans de telles circonstances, votre responsabilité sera engagée
            à deux conditions.
        </p>
        <p>
            Si vous n’avez pas mis fin au mandat en avisant les personnes concernées ou
            si vous laissez croire que la personne agit encore pour votre compte, vous
            pourrez être tenu responsable des actes posés par le mandataire auprès des
            tiers.
            <strong>
                <br />
            </strong>
            <br />
            <strong>Le mandataire qui excède son mandat : </strong>
            Lorsque le mandataire excède son mandat ou pose des gestes illégaux, la
            responsabilité du mandant n’est pas retenue et c’est plutôt le mandataire
            qui devient personnellement responsable des actes commis.
        </p>
        <p>
            <strong>Mandat apparent et tiers de mauvaise foi : </strong>
            En droit civil, il existe une protection conférée aux tiers de bonne foi
            leur permettant de retenir la responsabilité du mandant lorsqu’ils avaient
            des raisons légitimes de croire que la personne avec qui ils ont contracté
            étant le mandataire de ce dernier.
        </p>
        <p>
            Toutefois, cette protection ne s’étend pas aux tiers de mauvaise foi,
            c’est-à-dire, ceux qui auraient dû savoir ou qui ne pouvaient pas ignorer
            que la personne n’était pas le réel mandataire.<strong></strong>
        </p>
        <strong></strong>
        <p>
            <strong>Le mandat peut-il être présumé? </strong>
            Dans un couple marié, un mandat qualifié « d’automatique », existe et
            permet à l’un ou l’autre des conjoints d’accomplir des actes nécessaires
            aux besoins du ménage, et ce, tout en engageant la responsabilité de
            l’autre. Le cas le plus clair d’une telle situation est celui de l’époux ou
            de l’épouse qui fait effectuer des travaux de réparation urgents et
            nécessaires.
        </p>
        <p>
            <strong>Comment mettre un terme à un mandat ou à une procuration? </strong>
            Si les termes de la procuration ne stipulent pas déjà le moment où prendra
            fin le mandat, le mandant peut simplement la révoquer ou le mandataire peut
            y renoncer, tant que cette démission n’est pas donnée à contretemps.
        </p>
        <h2>
            Choisissez votre futur avocat en fonction de son domaine de droit!
        </h2>
        <p>
            Comme les situations personnelles se ressemblent d’une personne à une
            autre, il n’est pas surprenant que les disputes légales et que les litiges
            se ressemblent eux aussi. N’importe quel avocat vous le dira, certaines
            reviennent si souvent qu’elles en deviennent presque procédurales en droit.
            Cela signifie également que plusieurs avocats sont disposés à prendre ces
            causes en main! Voici donc les domaines de pratique les plus populaires!
        </p>
        <p>
            <strong>Divorce et séparation : </strong>
            Régler une séparation avec une poursuite en divorce est un mécanisme légal
            très commun. La complexité de tels dossiers est évidemment les demandes
            déraisonnables de l’une ou l’autre des parties au divorce et les
            contestations du calcul de pension alimentaire. Les services d’un avocat
            rendent toujours la démarche moins pénible, soyez-en assurés!
        </p>
        <p>
            <strong>Harcèlement criminel </strong>
            : Quelle que soit la cause à l’origine du harcèlement criminel vécu par la
            victime, les avocats sont en mesure de saisir rapidement les tribunaux pour
            obtenir des injonctions et mettre en place des mesures de sécurité pour
            protéger la victime. Il ne faut surtout pas hésiter à contacter un avocat
            en droit criminel si vous éprouvez de tels problèmes!
        </p>
        <p>
            <strong>Dommages-intérêts </strong>
            : Vous avez été victime d’une faute qui vous a causé un préjudice
            considérable? Vous pouvez solliciter un avocat pour entamer une poursuite
            en responsabilité civile et tenter d’obtenir une compensation sous forme de
            dommages-intérêts.
        </p>
        <p>
            <strong>Injonctions</strong>
            : Les injonctions sont des ordres de faire ou ne pas faire quelque chose
            émises exclusivement par la Cour supérieure. Obtenir un tel ordre de la
            cour demande évidemment une bonne raison ainsi que des preuves solides à
            l’appui.
        </p>
        <p>
            <strong>Pension alimentaire/Garde d’enfants</strong>
            : Qu’un ex-conjoint refuse de payer le montant de la pension fixé par le
            juge ou qu’il conteste le jugement de garde d’enfants, il vous faut agir
            rapidement auprès d’un avocat pour éviter que ce soit vos enfants qui
            paient le prix d’une bataille juridique.
        </p>
        <p>
            <strong>Demandes d’immigration</strong>
            : Vous souhaitez immigrer au Québec? Votre demande de résidence permanente
            a été rejetée? Vos droits ont été brimés en sol canadien? Quelle que soit
            votre cause, si vous n’êtes pas encore citoyen canadien, vous avec tout de
            même des droits qu’un avocat en droit de l’immigration peut faire valoir!
        </p>
        <h2>
            Questions pour votre avocat de Mirabel!
        </h2>
        <p>
            Bien que vous remettiez les commandes de votre dossier entre les mains d’un
            avocat, toujours est-il que vous demeurez responsable des décisions
            importantes concernant celui-ci. Vous serez donc d’accord pour dire que
            vous et votre avocat devez être sur la même longueur d’onde sur la façon de
            régler votre litige, n’est-ce pas? En posant quelques savantes questions,
            vous serez à même de déterminer si vous êtes en compagnie du bon avocat!
        </p>
        <p>
            <StaticImage placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}
                src="../images/questions-avocat-mirabel-formation-experience-reglement-amiable.jpeg"
                alt="questions-avocat-mirabel-formation-experience-reglement-amiable"

            />
        </p>
        <p>
            <strong>
                Une entente à l’amiable est-elle envisageable avec l’autre partie?
            </strong>
        </p>
        <p>
            Ce n’est pas parce que vous engagez un avocat que vous êtes obligés de
            porter votre cause jusqu’en cour. Il existe d’innombrables stratégies en
            matière de règlement de litiges qui peuvent être plus efficaces et surtout
            plus expéditifs que le procès traditionnel. Si vous êtes à la recherche
            d’une solution rapide, mais satisfaisante, parlez à votre avocat de la
            possibilité d’une entente à l’amiable!
        </p>
        <p>
            <strong>À combien s’élèvera le cout total de ses services?</strong>
        </p>
        <p>
            Les frais d’avocat ne sont pas les extravagances que les légendes urbaines
            tentent de vous faire croire, mais il est tout de même vrai que vous devrez
            débourser des sommes parfois considérables pour régler un litige si
            celui-ci s’éternise. Demander une estimation du coût des services est une
            façon judicieuse de vous protéger.
        </p>
        <p>
            <strong>Quels sont les mérites de votre cause?</strong>
        </p>
        <p>
            Vous avez peut-être l’impression que votre cause est solide et qu’elle est
            si solidement implantée dans le droit qu’elle devrait se rendre en Cour
            suprême. Vos impressions ne concordent toutefois pas toujours avec la
            réalité juridique. Que vous ayez raison ou tort, le droit étant la seule
            mesure du juste en société, il est possible que votre cause n’aille pas
            beaucoup d’assises juridiques pour la supporter. C’est donc le rôle de
            l’avocat de vous informer sur les chances de succès de votre cause en cour.
        </p>
        <p>
            <strong>Pouvez-vous aider ou contribuer au règlement du dossier?</strong>
        </p>
        <p>
            Plus vous êtes en mesure de fournir des preuves admissibles en cour, mieux
            votre avocat sera placé pour construire des arguments basés sur des points
            de droit solides. En lui demandant quel genre de contribution vous pouvez
            fournir, c’est une relation professionnelle plus prospère que vous bâtirez.
        </p>
        <p>
            <strong>À quoi ressemble son cheminement professionnel?</strong>
        </p>
        <p>
            Les différents domaines de droit demandent des qualifications uniques et
            distinctes. C’est pourquoi les avocats sont des professionnels aussi
            spécialisés. Informez-vous auprès de votre avocat des causes qu’il a
            entrepris dans son passé professionnel, cela s’avèrera un bon indicateur
            des chances de succès de votre dossier!
        </p>
        <h2>
            Soumissions Avocat vous aide dans votre recherche d’un avocat à Mirabel!
        </h2>
        <p>
            Cessez tout de suite de repousser le règlement de votre litige et engagez
            un avocat à Mirabel pour retrouver votre qualité de vie sans plus tarder!
            Les avocats sont des professionnels on ne peut plus qualifiés qui sauront
            vous aider, quelle que soit la gravité de l’impasse dans laquelle vous vous
            trouvez.
        </p>
        <p>
            <strong>
                Pour obtenir de tels services sans perdre votre temps à chercher à
                droite et à gauche, utilisez plutôt nos services! Nous sommes en mesure
                de vous mettre en contact avec les meilleurs avocats à Mirabel!
            </strong>
        </p>
        <p>
            <strong>
                Pour ce faire, vous n’avez qu’à remplir le formulaire au bas de la page
                et nous enverrons votre demande à 3 avocats dans votre région! Nos
                services sont gratuits et rapides, alors pourquoi hésiter?
            </strong>
        </p>

    </SeoPage>
)
export default Mirabel
